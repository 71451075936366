
const dateUtils = {
    getYearsold: (value: Date | string): number => {
        let date: Date;
        if (value instanceof Date)
            date = value;
        else
            date = new Date(value);

        let birthdayYear = date.getFullYear(),
            birthdayMonth = date.getMonth() + 1,
            birthdayDay = date.getDate();

        let age = 0;

        var d = new Date();
        let actualYear = d.getFullYear(),
            actualMonth = d.getMonth() + 1,
            actualDay = d.getDate();

        age = actualYear - birthdayYear;
        if (actualMonth < birthdayMonth || (actualMonth < birthdayMonth && actualDay < birthdayDay))
            age--;


        return age;
    },
    isBirthdayByMonthAndDay(value: Date | string): boolean {
        let date: Date;

        let day = 0, month = 0;
        if (!value)
            return false;
        else if (value instanceof Date)
            date = value;
        else
            date = new Date(value);
        let atualDate = new Date();
        day = date.getDate();
        month = date.getMonth();

        return day === atualDate.getDate() && month === atualDate.getMonth();
    },
    formartLocaleDate(value: Date | string): string {
        let date: Date;

        if (!value)
            return "";
        else if (value instanceof Date)
            date = value;
        else
            date = new Date(value);

        return Date.prototype.toString.call(date) === "Invalid Date" ? "" : date.toLocaleDateString();
    },
    datediff: (date1: Date, date2: Date) => {
        var year1 = date1.getFullYear(), month1 = date1.getMonth(), day1 = date1.getDate(),
            year2 = date2.getFullYear(), month2 = date2.getMonth(), day2 = date2.getDate();

        if (day1 < day2) {
            month1--;
            day1 += dateUtils.DaysInMonth(year2, month2);
        }

        if (month1 < month2) {
            year1--;
            month1 += 12;
        }
        return [Math.abs(year1 - year2), Math.abs(month1 - month2), Math.abs(day1 - day2)];
    },
    DaysInMonth: (year: number, month: number) => {
        let dt = new Date(year, month, 1, 12)
        return dt.getDate();
    },

    isLeapYear: (year: number) => {
        return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
    },
    getFormatedDiff(date1: Date | string, date2: Date | string): string {

        if (!date1)
            return "";
        else if (!date2)
            return "";
        let dt1: Date, dt2: Date;

        dt1 = new Date(date1);
        dt2 = new Date(date2);

        let diff = dateUtils.datediff(dt1, dt2);

        let formatedString = diff[0] + " anos, " + diff[1] + " meses e " + diff[2] + " dias";

        return formatedString;
    }

}
export default dateUtils;