import React, { Component } from 'react';
import IClient, { ProdutosDrogaria } from '../../Models/Client';
import dateUtils from '../../common/dateUtils';
import { Link } from 'react-router-dom';
import { PotencialDroga, Estagio, PotencialGeral, EstagioCoop } from '../../configs/perfil-compra';
import HTMLReactParser from 'html-react-parser';
export interface ClientProps {
    data: IClient
}

enum ActiveTabs {
    Drogaria,
    Coop
}

class index extends Component<ClientProps> {
    state = { activeTab: ActiveTabs.Drogaria }

    componentDidMount() {

        //habilita utilização de tooltips e popovers
        $('[data-toggle="tooltip"]').tooltip()
        $('[data-toggle="popover"]').popover()

        /* adiciona classe ativo e o icone na sanfona inicial se aberta */
        $(".collapse.show").each((index, el) => {
            $(el).prev(".card-header").find(".fa").addClass("fa-chevron-down").removeClass("fa-chevron-right");
            $(el).parent().addClass('active');
        });

        //adiciona classe ativo na sanfona
        $('.collapse').on('shown.bs.collapse', (el) => {
            $(el.currentTarget).parent().addClass('active');
        });

        //remove classe ativa na sanfona
        $('.collapse').on('hidden.bs.collapse', (el) => {
            $(el.currentTarget).parent().removeClass('active');
        });

        //adiciona e remove icones ativo e inativo da sanfona
        $(".collapse").on('show.bs.collapse', (el) => {
            $(el.currentTarget).prev(".card-header").find(".fa").removeClass("fa-chevron-right").addClass("fa-chevron-down");
        }).on('hide.bs.collapse', (el) => {
            $(el.currentTarget).prev(".card-header").find(".fa").removeClass("fa-chevron-down").addClass("fa-chevron-right");
        });

    }

    render() {
        const { data } = this.props;

        return (
            <React.Fragment>
                {/*  DIV SUCESSO */}
                {/* style={"display: block"} */}
                <div id="divSucesso" className="card sombra p-3 pt-4 p-md-5" >

                    <h1 className="mb-4 text-capitalize">{data.nome}</h1>

                    <div className="row mb-4 dados">
                        {/* 
                        ------------------------------------------------------------------------
						////// DADOS - ITEM 1
				    	--------------------------------------------------------------------------
                     */}
                        <div className="col-12 col-sm col-md-4">
                            <div className="card">
                                <div className="card-body p-3">
                                    <h4>Data de <br className="d-none d-sm-block d-md-none" />Aniversário:</h4>
                                    <p>{dateUtils.formartLocaleDate(data.datNascimento)}</p>

                                    {/* -- só aparece se for o mês de aniversário do cara -- */
                                        dateUtils.isBirthdayByMonthAndDay(data.datNascimento) &&
                                        <i className="fa fa-birthday-cake fa-2x bolo"></i>}
                                    {/* --- FIM  -- */}
                                </div>
                            </div>
                        </div>
                        {/* --- FIM ITEM 1 -- */}

                        {/* 
                    ------------------------------------------------------------------------
						////// DADOS - ITEM 2
					-------------------------------------------------------------------------- */}
                        <div className="col-12 col-sm col-md-4 my-3 my-sm-0">
                            <div className="card my-xs-3 my-sm-0">
                                <div className="card-body p-3">
                                    <h4>Cooperado <br className="d-none d-sm-block d-md-none" />desde:</h4>
                                    <p>{dateUtils.formartLocaleDate(data.datAssociacao)}</p>
                                </div>
                            </div>
                        </div>
                        {/* --- FIM ITEM 2 -- */}

                        {/* 
                        ------------------------------------------------------------------------
						////// DADOS - ITEM 3
					-------------------------------------------------------------------------- 
                        */}
                        <div className="col-12 col-sm-5 col-md-4">
                            <div className="card">
                                <div className="card-body p-3">
                                    <h4>Loja <br className="d-none d-sm-block d-md-none" />Prefencial:</h4>
                                    <p>{data.lojaPreferencialSup}</p>
                                </div>
                            </div>
                        </div>
                        {/* --- FIM ITEM 3 -- */}
                    </div>

                    <ul className="nav nav-tabs" id="abas" role="tablist">
                        <li className="nav-item" role="presentation">
                            <a className="nav-link active" onClick={() => { this.setState({ activeTab: ActiveTabs.Drogaria }) }} id="aba-drogaria" data-toggle="tab" href="#drogaria" role="tab" aria-controls="drogaria" aria-selected="true">Drogaria</a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a className="nav-link" id="aba-geral" onClick={() => { this.setState({ activeTab: ActiveTabs.Coop }) }} data-toggle="tab" href="#geral" role="tab" aria-controls="geral" aria-selected="false">COOP</a>
                        </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                        {/*
                        ------------------------------------------------------------------------
                            ////// ABA DROGARIA
                        -------------------------------------------------------------------------- */}
                        <div className="tab-pane fade show active mt-4" id="drogaria" role="tabpanel" aria-labelledby="aba-drogaria">
                            <div className="accordion" id="infoDROGA">
                                {/* 
                                ------------------------------------------------------------------------
								////// SANFONA 1 - SEGMENTAÇÃO // drogaItem1
							    --------------------------------------------------------------------------
                                */}
                                <div className="card">
                                    <div className="card-header" id="card1">
                                        <h2 className="mb-0">
                                            <button className="btn btn-block btn-accord text-left collapsed" type="button" data-toggle="collapse" data-target="#drogaItem1">
                                                <i className="fa fa-chevron-right"></i>Perfil de compra
										</button>
                                        </h2>
                                    </div>

                                    <div id="drogaItem1" className="collapse" >
                                        <div className="card-body">
                                            <div className="row perfil">
                                                {/* 
                                            ------------------------------------------------------------------------
												////// CARD POTENCIAL DE COMPRA
											--------------------------------------------------------------------------
                                           */}
                                                <div className="col-lg-4">
                                                    <div className="card">
                                                        <div className="card-body" id="PotencialDroga">
                                                            <Link to="/" innerRef={e => { }}
                                                                className="float-right" role="button" data-toggle="popover" data-placement="bottom" data-trigger="focus"
                                                                title="Potencial de compra"
                                                                data-content="Diferencia cada cooperado de acordo com o nível de fidelidade e valor gasto nas drogarias, direcionando para a estratégia de venda.">
                                                                <svg width="1.4em" height="1.4em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg" className="align-top text-secondary">
                                                                    <path fillRule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                                    <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588z" />
                                                                    <circle cx="8" cy="4.5" r="1" />
                                                                </svg>
                                                            </Link>

                                                            <h4 className="card-title"><strong>Potencial</strong> de compra</h4>

                                                            <span className="badge badge-pill mb-3 badge-danger">{PotencialDroga[data.segmentacaoFinanceira].titulo}</span>
                                                            <span className={"estrelas " + PotencialDroga[data.segmentacaoFinanceira].titulo}>
                                                                <i className="fa fa-star d-none" aria-hidden="true"></i>
                                                                <i className="fa fa-star d-none" aria-hidden="true"></i>
                                                                <i className="fa fa-star d-none" aria-hidden="true"></i>
                                                                <i className="fa fa-star d-none" aria-hidden="true"></i>
                                                            </span>
                                                            <p className="card-text">{HTMLReactParser(PotencialDroga[data.segmentacaoFinanceira].descricao)}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/*  
                                                    ------------------------------------------------------------------------
                                                    ////// CARD ESTAGIO
                                                    --------------------------------------------------------------------------
                                                */}
                                                <div className="col-lg-4 py-3 py-lg-0">
                                                    <div className="card">
                                                        <div className="card-body" id="EstagioDroga">
                                                            <Link to="/" innerRef={e => { }}
                                                                className="float-right" role="button" data-toggle="popover" data-placement="bottom" data-trigger="focus"

                                                                title="Estágio de compra"
                                                                data-content="Acompanhe a fase de compra de cada cooperado e veja como conquistar mais vendas."
                                                            >
                                                                <svg width="1.4em" height="1.4em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg" className="align-top text-secondary">
                                                                    <path fillRule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                                    <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588z" />
                                                                    <circle cx="8" cy="4.5" r="1" />
                                                                </svg>
                                                            </Link>

                                                            <h4 className="card-title"><strong>Estágio</strong> de compra</h4>
                                                            <span className="badge badge-pill mb-3 badge-danger">{Estagio[data.cicloDeVida].titulo}</span>
                                                            <p className="card-text">{HTMLReactParser(Estagio[data.cicloDeVida].descricao)}</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* 
                                            ------------------------------------------------------------------------
												////// CARD ESTIMULO
											--------------------------------------------------------------------------
                                           */}
                                                <div className="col-lg-4">
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <h4 className="card-title"><strong>Estímulo</strong> de consumo</h4>
                                                            <span className="badge badge-pill mb-3 badge-danger">#{data.clusterMissao ? data.clusterMissao : 'Nenhum'}</span>
                                                            <p className="card-text">Tipo de categoria que o cooperado mais compra na Coop. </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!--- FIM SANFONA 1 --> */}

                                {/* <!------------------------------------------------------------------------
								////// SANFONA 2 - OFERTAS APP // drogaItem2
							--------------------------------------------------------------------------> */}
                                <div className="card">
                                    <div className="card-header" id="card2">
                                        <h2 className="mb-0">
                                            <button className="btn btn-block btn-accord text-left collapsed" type="button" data-toggle="collapse" data-target="#drogaItem2" aria-expanded="false" aria-controls="drogaItem2">
                                                <i className="fa fa-chevron-right"></i>Ofertas do Aplicativo
										</button>
                                        </h2>
                                    </div>

                                    <div id="drogaItem2" className="collapse" aria-labelledby="card3">
                                        <div className="card-body">

                                            {/* <!-- BANNER ALERTA --> */}
                                            <div className="alert alert-banner sombra mb-4" role="alert">
                                                <div className=" d-flex align-items-center justify-content-center text-center">
                                                    <span>Aproveite para promover o APP com Ofertas exclusivas!</span>
                                                </div>
                                            </div>
                                            {/* <!-- / BANNER ALERTA --> */}

                                            <ul className="nav nav-pills nav-fill mb-4" id="pills" role="tablist">
                                                <li className="nav-item" role="presentation">
                                                    <a className="nav-link active" id="aba-oqcompra" data-toggle="pill" href="#oqcompra" role="tab" aria-controls="oqcompra" aria-selected="true">Especiais para você</a>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <a className="nav-link" id="aba-podecomprar" data-toggle="pill" href="#podecomprar" role="tab" aria-controls="podecomprar" aria-selected="false">Você também pode gostar</a>
                                                </li>
                                            </ul>

                                            <div className="tab-content" id="pills-tabContent">
                                                <div className="tab-pane fade show active" id="oqcompra" role="tabpanel" aria-labelledby="aba-oqcompra">

                                                    <div className="table-responsive">
                                                        {/* <!------------------------------------------------------------------------
														////// TABELA OFERTAS APP // className="table table-hover"
													--------------------------------------------------------------------------> */}
                                                        <table className="table table-hover">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">Descrição do produto</th>
                                                                    <th scope="col">Valor/Desconto</th>
                                                                    <th scope="col">Vigência</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {data.ofertasDrograria.filter(function (item) {
                                                                    return item.vitrine === "JaComprou";
                                                                }).map(function (item, index) {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td>
                                                                                <img src={`${process.env.PUBLIC_URL}/api/img/${item.idFamilia}`} height="50" /> {item.txtDescricaoOferta === '""' ? '' : item.txtDescricaoOferta}
                                                                            </td>
                                                                            <td>{item.desconto}% de desconto</td>
                                                                            <td>{dateUtils.formartLocaleDate(item.datInicio)} à {dateUtils.formartLocaleDate(item.datFim)}</td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                            </tbody>
                                                        </table>
                                                        {/* <!--- FIM tabela --> */}
                                                    </div>

                                                </div>
                                                <div className="tab-pane fade" id="podecomprar" role="tabpanel" aria-labelledby="aba-podecomprar">

                                                    <div className="table-responsive">
                                                        {/* <!------------------------------------------------------------------------
														////// TABELA O QUE PODE COMPRAR //  className="table table-hover"
													--------------------------------------------------------------------------> */}
                                                        <table className="table table-hover">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">Descrição do produto</th>
                                                                    <th scope="col">Valor/Desconto</th>
                                                                    <th scope="col">Vigência</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {data.ofertasDrograria.filter(function (item) {
                                                                    return item.vitrine === "PodeGostar";
                                                                }).map(function (item, index) {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td>
                                                                                <img src={`${process.env.PUBLIC_URL}/api/img/${ item.idFamilia}`} height="50" /> {item.txtDescricaoOferta === '""' ? '' : item.txtDescricaoOferta}
                                                                            </td>
                                                                            <td>{item.desconto}% de desconto</td>
                                                                            <td>{dateUtils.formartLocaleDate(item.datInicio)} à {dateUtils.formartLocaleDate(item.datFim)}</td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                            </tbody>
                                                        </table>
                                                        {/* <!--- FIM tabela --> */}
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                {/* <!--- FIM SANFONA 2 --> */}

                                {/* <!------------------------------------------------------------------------
								////// SANFONA 3 - HISTORICO // drogaItem3
							--------------------------------------------------------------------------> */}
                                <div className="card">
                                    <div className="card-header" id="card3">
                                        <h2 className="mb-0">
                                            <button className="btn btn-block btn-accord text-left collapsed" type="button" data-toggle="collapse" data-target="#drogaItem3" aria-expanded="false" aria-controls="drogaItem3">
                                                <i className="fa fa-chevron-right"></i>Histórico de compras de medicamentos
										</button>
                                        </h2>
                                    </div>

                                    <div id="drogaItem3" className="collapse" aria-labelledby="card4" >
                                        <div className="card-body">

                                            {/* <!-- BANNER ALERTA --> */}
                                            <div className="alert alert-banner sombra mb-4" role="alert">
                                                <div className=" d-flex align-items-center justify-content-center text-center">
                                                    Ofereça medicamentos que o cooperado já compra
											</div>
                                            </div>
                                            {/* <!-- / BANNER ALERTA --> */}

                                            <div className="table-responsive">
                                                {/* <!------------------------------------------------------------------------
												////// TABELA HISTORICO DE COMPRAS //  className="table table-hover"
											--------------------------------------------------------------------------> */}
                                                <table className="table table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Data</th>
                                                            <th scope="col">Descrição do produto</th>
                                                            <th scope="col">Quantidade</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {data.produtosDrogaria.map((x: ProdutosDrogaria, index: number) =>
                                                            <tr key={index}>
                                                                <td>{dateUtils.formartLocaleDate(x.dataCompra)}</td>
                                                                <td>{x.nomeProduto}</td>
                                                                <td>{x.qtde} {x.qtde <= 1 ? "unidade" : "unidades"} </td>
                                                            </tr>)}
                                                    </tbody>
                                                </table>
                                                {/* <!--- FIM tabela --> */}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                {/* <!--- FIM SANFONA 3 --> */}
                            </div>
                        </div>
                        {/* <!--- FIM ABA DROGARIA --> */}



                        {/* <!------------------------------------------------------------------------
						////// ABA GERAL
					--------------------------------------------------------------------------> */}
                        <div className="tab-pane fade mt-4" id="geral" role="tabpanel" aria-labelledby="aba-geral">
                            <div className="accordion" id="infoGERAL">
                                {/* <!------------------------------------------------------------------------
								////// SANFONA1 - PERFIL // geralItem1
							--------------------------------------------------------------------------> */}
                                <div className="card">
                                    <div className="card-header" id="card1">
                                        <h2 className="mb-0">
                                            <button className="btn btn-block btn-accord text-left collapsed" type="button" data-toggle="collapse" data-target="#geralItem1" aria-expanded="false" aria-controls="geralItem1">
                                                <i className="fa fa-chevron-right"></i>Perfil de compra
										</button>
                                        </h2>
                                    </div>

                                    <div id="geralItem1" className="collapse" aria-labelledby="card2" >
                                        <div className="card-body">
                                            <div className="row perfil">
                                                {/* <!------------------------------------------------------------------------
												////// CARD POTENCIAL DE COMPRA
											--------------------------------------------------------------------------> */}
                                                <div className="col-lg-4">
                                                    <div className="card">
                                                        <div className="card-body" id="PotencialGeral">
                                                            <Link to="/" innerRef={() => { }} className="float-right" role="button" data-toggle="popover" data-placement="bottom" data-trigger="focus"
                                                                title="Potencial de compra"
                                                                data-content="Diferencia cada cooperado de acordo com o nível de fidelidade e valor gasto nas drogarias, direcionando para a estratégia de venda.">
                                                                <svg width="1.4em" height="1.4em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg" className="align-top text-secondary">
                                                                    <path fillRule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                                    <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588z" />
                                                                    <circle cx="8" cy="4.5" r="1" />
                                                                </svg>
                                                            </Link>

                                                            <h4 className="card-title"><strong>Potencial</strong> de compra</h4>
                                                            <span className="badge badge-pill mb-3 badge-danger">{PotencialGeral[data.segmentacaoFinanceiraSup].titulo}</span>
                                                            <span className={'estrelas ' + PotencialGeral[data.segmentacaoFinanceiraSup].titulo}>
                                                                <i className="fa fa-star d-none" aria-hidden="true"></i>
                                                                <i className="fa fa-star d-none" aria-hidden="true"></i>
                                                                <i className="fa fa-star d-none" aria-hidden="true"></i>
                                                                <i className="fa fa-star d-none" aria-hidden="true"></i>
                                                            </span>

                                                            <p className="card-text"> {HTMLReactParser(PotencialGeral[data.segmentacaoFinanceiraSup].descricao)}</p>

                                                        </div>
                                                    </div>
                                                </div>

                                                {/* <!------------------------------------------------------------------------
												////// CARD ESTAGIO
											--------------------------------------------------------------------------> */}
                                                <div className="col-lg-4 py-3 py-lg-0">
                                                    <div className="card">
                                                        <div className="card-body" id="EstagioGeral">
                                                            <Link to="/" innerRef={() => { }}
                                                                className="float-right" role="button" data-toggle="popover" data-placement="bottom" data-trigger="focus"
                                                                title="Estágio de compra"
                                                                data-content="Acompanhe a fase de compra de cada cooperado e veja como conquistar mais vendas.">
                                                                <svg width="1.4em" height="1.4em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg" className="align-top text-secondary">
                                                                    <path fillRule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                                    <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588z" />
                                                                    <circle cx="8" cy="4.5" r="1" />
                                                                </svg>
                                                            </Link>
                                                            <h4 className="card-title"><strong>Estágio</strong> de compra</h4>

                                                            <span className="badge badge-pill mb-3 badge-danger">{EstagioCoop[data.cicloDeVidaSup].titulo}</span>

                                                            <p className="card-text">{HTMLReactParser(EstagioCoop[data.cicloDeVidaSup].descricao)}</p>


                                                        </div>
                                                    </div>
                                                </div>
                                                {/* 
                                                <!------------------------------------------------------------------------
												////// CARD ESTIMULO
											--------------------------------------------------------------------------> */}
                                                <div className="col-lg-4">
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <h4 className="card-title"><strong>Estímulo</strong> de consumo</h4>
                                                            <span className="badge badge-pill mb-3 badge-danger">#{data.clusterMissaoSup ? data.clusterMissaoSup : 'Nenhum'}</span>
                                                            <p className="card-text">Tipo de categoria que o cooperado mais compra na Coop. </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!--- FIM SANFONA 1 --> */}

                                {/*
							<!------------------------------------------------------------------------
								////// SANFONA 2 - OFERTAS APP // geralItem2
							--------------------------------------------------------------------------> */}
                                <div className="card " >
                                    <div className="card-header" id="card2">
                                        <h2 className="mb-0">
                                            <button className="btn btn-block btn-accord text-left collapsed" type="button" data-toggle="collapse" data-target="#geralItem2" aria-expanded="false" aria-controls="geralItem2">
                                                <i className="fa fa-chevron-right"></i>Ofertas do Aplicativo
										</button>
                                        </h2>
                                    </div>

                                    <div id="geralItem2" className="collapse" aria-labelledby="card3">
                                        <div className="card-body">

                                            {/* <!-- BANNER ALERTA --> */}
                                            <div className="alert alert-banner sombra mb-4" role="alert">
                                                <div className=" d-flex align-items-center justify-content-center text-center">
                                                    <span>Aproveite para promover o APP com Ofertas exclusivas!</span>
                                                </div>
                                            </div>
                                            {/* <!-- / BANNER ALERTA --> */}

                                            <ul className="nav nav-pills nav-fill mb-4" id="pills" role="tablist">
                                                <li className="nav-item" role="presentation">
                                                    <a className="nav-link active" id="aba-oqcompra" data-toggle="pill" href="#oqcompraCoop" role="tab" aria-controls="oqcompra" aria-selected="true">Especiais para você</a>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <a className="nav-link" id="aba-podecomprar" data-toggle="pill" href="#podecomprarCoop" role="tab" aria-controls="podecomprar" aria-selected="false">Você também pode gostar</a>
                                                </li>
                                            </ul>

                                            <div className="tab-content" id="pills-tabContent">
                                                <div className="tab-pane fade show active" id="oqcompraCoop" role="tabpanel" aria-labelledby="aba-oqcompra">

                                                    <div className="table-responsive">
                                                        {/* <!------------------------------------------------------------------------
                                                        ////// TABELA OFERTAS APP // className="table table-hover"
                                                        TODO Inplementar isso aqui
													--------------------------------------------------------------------------> */}
                                                        <table className="table table-hover ">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">Descrição do produto</th>
                                                                    <th scope="col">Valor/Desconto</th>
                                                                    <th scope="col">Vigência</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {data.ofertasCoop.filter(function (item) {
                                                                    return item.vitrine === "JaComprou";
                                                                }).map(function (item, index) {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td>
                                                                                <img src={`${process.env.PUBLIC_URL}/api/img/${item.idFamilia}`} height="50" /> {item.txtDescricaoOferta === '""' ? '' : item.txtDescricaoOferta}
                                                                            </td>
                                                                            <td>{item.desconto}% de desconto</td>
                                                                            <td>{dateUtils.formartLocaleDate(item.datInicio)} à {dateUtils.formartLocaleDate(item.datFim)}</td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                            </tbody>
                                                        </table>
                                                        {/* <!--- FIM tabela --> */}
                                                    </div>

                                                </div>
                                                <div className="tab-pane fade" id="podecomprarCoop" role="tabpanel" aria-labelledby="aba-podecomprar">

                                                    <div className="table-responsive">
                                                        {/* <!------------------------------------------------------------------------
                                                        ////// TABELA O QUE PODE COMPRAR //  className="table table-hover"
                                                        TODO Inplementar isso aqui
													--------------------------------------------------------------------------> */}
                                                        <table className="table table-hover">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">Descrição do produto</th>
                                                                    <th scope="col">Valor/Desconto</th>
                                                                    <th scope="col">Vigência</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {data.ofertasCoop.filter(function (item) {
                                                                    return item.vitrine === "PodeGostar";
                                                                }).map(function (item, index) {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td>
                                                                                <img src={`${process.env.PUBLIC_URL}/api/img/${item.idFamilia}`} height="50"/> {item.txtDescricaoOferta === '""' ? '' : item.txtDescricaoOferta}
                                                                            </td>
                                                                            <td>{item.desconto}% de desconto</td>
                                                                            <td>{dateUtils.formartLocaleDate(item.datInicio)} à {dateUtils.formartLocaleDate(item.datFim)}</td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                            </tbody>
                                                        </table>
                                                        {/* <!--- FIM tabela --> */}
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                {/* FIM SANFONA 2 */}
                            </div>
                        </div>
                        {/* FIM ABA GERAL */}
                    </div>

                </div>

            </React.Fragment >
        );
    }
}

export default index;