import React from 'react';
import { Route, Switch, Redirect, BrowserRouter } from 'react-router-dom';
import DefaultTemplate from './template/default-template';
import Users from './pages/users'
import Erro404 from './pages/Erros/Erro404';
import UnAuthorized from './pages/Erros/UnAuthorized';
import Login from './pages/login';
import { isAuthenticated, isInRole } from './services/auth';
import Home from './pages/home';

const PrivateRoute = ({ component: Component, roles, ...rest }: any) => (
    <Route
        {...rest}
        render={props =>
            isAuthenticated() ?
                isInRole(roles) ?
                    (< Component {...props} />) : (<Redirect to={{ pathname: "/unAuthorized", state: { from: props.location } }} />)

                : (
                    <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
                )
        }
    />
);

const Routes: React.FC = () => {
    return (
        <React.Fragment>

            <BrowserRouter basename={process.env.PUBLIC_URL} >
                <Switch>
                <Route path="/login" component={Login} exact={true}></Route>
                    <Route component={home}></Route>               
                    {/* <Route component={Erro404} path="*" exact ></Route> */}
                </Switch>
            </BrowserRouter>
        </React.Fragment>

    );
};

export const home = () => {
    return (
        <DefaultTemplate>
            <Switch>
                <PrivateRoute path="/" component={Home} exact={true} />
                <PrivateRoute roles={['admin']} path="/admin/user" component={Users} exact />
                <Route path="/unAuthorized" component={UnAuthorized} exact></Route>
                <Route path="*">
                    <Erro404 />
                </Route>
            </Switch>
        </DefaultTemplate>
    );
}


export default Routes; 