import React, { Component } from 'react';
import Logo from '../../assets/img/logo.png';
class index extends Component {
    render() {
        return (
            <footer className="py-2">
                <div className="container-lg d-flex space-between center">                   
                    <div className="d-inline text-white mt-3">
                        Desenvolvido por <a href="https://ibopedtm.com/" title="IBOPEdtm" className="btn-link" target="blank">IBOPEdtm</a>
                    </div>

                    <div className="d-inline text-white">
                        <img src={Logo} alt="COOP" loading="lazy" />
                    </div>
                </div>
            </footer>
        );
    }
}

export default index;