import axios, { AxiosInstance } from "axios";
import { getToken } from "./auth";

const api: AxiosInstance = axios.create({
    baseURL: process.env.PUBLIC_URL
});

api.interceptors.request.use(async config => {
    const token = getToken();
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

export default api;