export const serializeModels = (data?: any): string => {
    if (data === undefined) { return ''; }

    const params = new URLSearchParams();

    for (const key in data) {
        if (!data[key]) {
            continue;
        }
        if (Object.prototype.toString.call(data[key]) === '[object Date]') {
            if (data[key].toString() === "Invalid Date")
                continue;
            // Se for do tipo date ele converte para o patrão ISO
            params.set(key, data[key].toISOString());
        } else { params.set(key, data[key]); }
    }

    return `${params.toString()}`;
}
export const removeCharacteres = (data: any): string => {
    if (data)
        return data.replace(/[^0-9]/g, "");
    else return "";
}
