import React, { Component } from 'react';
import Pagination from 'react-js-pagination';

export interface paginationProps {
    itemsPerPage: number,
    page: number,
    tot: number,
    onChangePage: (pageNumber: number) => void
}

class index extends Component<paginationProps> {

    handlePageChange = (activePage: number) => {       
        this.props?.onChangePage(activePage);
    }

    render() {
        const { itemsPerPage, tot, page } = this.props;
        return (
            <nav>
                <Pagination
                    hideNavigation={true}
                    linkClass="page-link"
                    innerClass="pagination justify-content-end"
                    activeLinkClass="active"
                    itemClass="page-item"
                    activePage={page}
                    itemsCountPerPage={itemsPerPage}
                    totalItemsCount={tot}
                    pageRangeDisplayed={5}
                    onChange={this.handlePageChange.bind(this)} />
            </nav>
        );
    }
}

export default index;