import React, { Component } from 'react';

export enum MessagerType {
    error = 'danger',
    primary = 'primary',
    secondary = 'secondary',
    success = 'success',
    warning = 'warning',
    info = 'info',
    light = 'light',
    dark = 'dark'
}

export interface AlertProps {
    showCloseButton: boolean,
    visible: boolean,
    onSetMessager: () => void,
    onClose?: () => void
}

class Index extends Component<AlertProps> {
    state = {
        message: '',
        type: MessagerType.info
    }
    /**
     *
     */
    constructor(props: any) {
        super(props);
        this.showAlert = this.showAlert.bind(this);
    }


    showAlert(type: MessagerType, message?: string) {
        this.setState({
            message,
            type
        });
        this.props.onSetMessager();
    }
    render() {
        const { children, showCloseButton, visible } = this.props;
        const { type, message } = this.state;
        return (
            <div className={"alert alert-" + type + " alert-dismissible fade " + (visible ? ' show' : 'd-none')} >
                {message.indexOf('<br') >= 0 ?
                    message.split('<br />').map((x: any, index: number) => <span key={index}>{index !== 0 && <br />}{x}</span>)
                    : message}
                {children}
                {showCloseButton && <button type="button" className="close" aria-label="Close" onClick={()=>{this.props.onClose?.call(this)}}>
                    <span aria-hidden="true">&times;</span>
                </button>}
            </div >
        );
    }
}

export default Index;