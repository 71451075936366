interface IDictinary {
    [key: string]: { titulo: string, descricao: string }
}

interface IDictinaryNumber {
    [key: number]: { titulo: string, descricao: string }
}

export const PotencialDroga: IDictinary = {
    null: {
        titulo: "",
        descricao: "Não há registros de compras deste cooperado. <strong>Oriente e estimule a identificação em suas compras.<strong>"
    },
    A: {
        titulo: "A",
        descricao: "Cooperado excelente, com alta frequência e que sempre compra na drogaria. <strong>Ofereça atendimento diferenciado e benefícios exclusivos.</strong>"
    },
    B: {
        titulo: "B",
        descricao: "Cooperado bom, que compra às vezes na drogaria, mas tem potencial a ser explorado. <strong>Ofereça atendimento diferenciado e ofertas exclusivas.</strong> "
    },
    C: {
        titulo: "C",
        descricao: "Cooperado ocasional, que compra pouco na drogaria. <strong>Estimule a compra e informe os benefícios de ser cooperado.</strong> "
    }
};

export const PotencialGeral: IDictinaryNumber = {
    0: {
        titulo: "",
        descricao: "Não há registros de compras deste cooperado. <strong>Oriente e estimule a identificação em suas compras.<strong>"
    },
    1: {
        titulo: "A",
        descricao: "Cooperado excelente, com alto gasto na Coop e muito potencial de compra. Ofereça um atendimento diferenciado e benefícios exclusivos. "
    },
    2: {
        titulo: "B",
        descricao: "Cooperado excelente, com alto gasto na Coop e médio potencial de compra. Ofereça um atendimento diferenciado e ofertas exclusivas. "
    },
    3: {
        titulo: "C",
        descricao: "Cooperado com potencial para comprar mais produtos. Estimule a compra."
    },
    4: {
        titulo: "D",
        descricao: "Cooperado com baixa frequência na Coop. Estimule a compra."
    }
};

export const Estagio: IDictinary = {
    null: {
        titulo: "",
        descricao: "Não há registros de compras deste cooperado. <strong>Oriente e estimule a identificação em suas compras.<strong>"
    },
    Ativação: {
        titulo: "Ativação",
        descricao: "Cooperado em fase inicial de relacionamento. <strong>Apresente e fortaleça os benefícios de ser cooperado e de informar o CPF no caixa.</strong>"
    },
    Desenvolvimento: {
        titulo: "Desenvolvimento",
        descricao: "Cooperado frequente no dia a dia da Coop, com potencial para comprar mais produtos. <strong>Apresente ofertas.</strong>"
    },
    Rentenção: {
        titulo: "Retenção",
        descricao: "Está comprando cada vez menos na Coop. Incentive a retomar o consumo. <strong>Ofereça ofertas exclusivas e reforce os benefícios de ser cooperado.</strong>"
    },
    Recuperacao: {
        titulo: "Recuperação",
        descricao: "Não compra na Coop há bastante tempo. Queremos que volte a comprar conosco. <strong>Estimule o consumo e relembre os benefícios de ser cooperado.</strong>"
    },
    Rentabilização: {
        titulo: "Rentabilização",
        descricao: "Cooperado que já frequenta a Coop e possui alto potencial de compra. <strong>Apresente ofertas e benefícios exclusivos.</strong>"
    }
};


export const EstagioCoop: IDictinary = {
    null: {
        titulo: "",
        descricao: "Não há registros de compras deste cooperado. <strong>Oriente e estimule a identificação em suas compras.<strong>"
    },
    ATIVACAO: {
        titulo: "Ativação",
        descricao: "Cooperado em fase inicial de relacionamento. <strong>Apresente e fortaleça os benefícios de ser cooperado e de informar o CPF no caixa.</strong>"
    },
    DESENVOLVIMENTO: {
        titulo: "Desenvolvimento",
        descricao: "Cooperado frequente no dia a dia da Coop, com potencial para comprar mais produtos. <strong>Apresente ofertas.</strong>"
    },
    RETENCAO: {
        titulo: "Retenção",
        descricao: "Está comprando cada vez menos na Coop. Incentive a retomar o consumo. <strong>Ofereça ofertas exclusivas e reforce os benefícios de ser cooperado.</strong>"
    },
    RECUPERACAO: {
        titulo: "Recuperação",
        descricao: "Não compra na Coop há bastante tempo. Queremos que volte a comprar conosco. <strong>Estimule o consumo e relembre os benefícios de ser cooperado.</strong>"
    },
    RENTABILIZACAO: {
        titulo: "Rentabilização",
        descricao: "Cooperado que já frequenta a Coop e possui alto potencial de compra. <strong>Apresente ofertas e benefícios exclusivos.</strong>"
    }
};
