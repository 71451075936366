
export const Validators = {

    maxLength: function (value: any, maxLength?: number): boolean {
        if (!value)
            return false;
        else
            return !maxLength ? true : value.length <= maxLength;

    },
    required: (value: any): boolean => {
        let formIsValid = true;
        if (value instanceof Date) {
            formIsValid = Date.prototype.toString.call(value) !== "Invalid Date";
        } else if (value instanceof Array) {
            formIsValid = value.length > 0
        }
        else
            formIsValid = !!value;

        return formIsValid;
    },
    fullName: (value: any): boolean => {
        if (value)
            return value.match(/^[a-zA-Z]+(?:\s[a-zA-Z]+)+$/);
        else
            return false;
    },
    isEmail: (value: any): boolean => {
        const patternUnicode = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return patternUnicode.test(value);
    }
}

