import React, { Component, FormEvent } from 'react';
import api from '../../services/api';
import { AxiosResponse } from 'axios';
import Alert, { MessagerType } from '../../components/Alert';
import { withRouter } from 'react-router-dom';
import { IUser, IPagedUser } from '../../Models/User';
import { serializeModels } from '../../common/serialize';
import ModalUser from './modal-user/index';
import Pagination from '../../components/pagination';

class Index extends Component<any> {
    public alert: React.RefObject<Alert>;
    public defaultUser: IUser = {
        id: 0,
        email: '',
        nome: '',
        userName: '',
        senha: '',
        ativo: true,
        dataCriacao: new Date(),
        ultimaModificacao: new Date(),
        roles: ['user'],
    };

    state = {
        users: [] as Array<IUser>,
        editUser: this.defaultUser,
        tot: 0,
        nome: '',
        page: 1,
        qtdeItems: 10,
        isInload: false,
        showAlert: false
    };
    /**
     *
     */
    constructor(props: any) {
        super(props);
        this.alert = React.createRef();
        this.handlerSearch(1);
    }

    closeAlert = () => {
        this.setState({ showAlert: false });
    }

    showMessage = () => {
        this.setState({ showAlert: true });
    }

    handlerOnSuccess = () => {
        this.setState({ page: 1 });
        this.closeAlert();
        this.handlerSearch(1);
    }

    handlerChangePage = (activePage: number) => {
        this.closeAlert();
        this.handlerSearch(activePage);
        this.setState({ page: activePage });
    }

    handlerSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        this.closeAlert();
        this.handlerSearch(1);
    }

    handlerResetEditUser = () => {
        this.setState({
            editUser: this.defaultUser
        });
    }

    handlerSearch = async (page: number) => {
       
        const { nome, qtdeItems } = this.state
        const filter = serializeModels({
            nome,
            page,
            qtdeItems
        });

        try {
            const response = await api.get("/api/AdminUser?" + filter);
            const pagedUsers = response.data as IPagedUser;
            this.setState({ users: pagedUsers.items, tot: pagedUsers.tot });
            if (pagedUsers.tot === 0)
                this.alert.current?.showAlert(MessagerType.error, 'Sem resultados para sua busca. Tente outro termo.');
        } catch (err) {
            if (err.isAxiosError) {
                const { statusText } = err.response as AxiosResponse;
                this.alert.current?.showAlert(MessagerType.error,
                    "Houve um problema interno, Tente novamente mais tarde.");
                console.log(statusText);
            }
            else {
                this.alert.current?.showAlert(MessagerType.error,
                    "Houve um problema interno, Tente novamente mais tarde.");
            };
        };
    }
    render() {
        return (<div className="container-lg my-4">

            <h1 className="mb-4">Consulta de usuários</h1>
            <div className="row">
                <div className="col-3 col-md-6">
                    <button type="button" className="btn btn-light mr-4" data-toggle="modal" data-target="#ModalCadastro" onClick={this.handlerResetEditUser}>
                        <i className="fa fa-user-plus" aria-hidden="true"></i> <span className="d-none d-sm-inline">Adicionar</span>
                    </button>
                </div>

                <div className="col-9 col-md-6 text-right">
                    <form onSubmit={this.handlerSubmit} className="d-flex bd-highlight">
                        <div className="pr-2 flex-grow-1 bd-highlight">
                            <input type="text" className="form-control" id="txtBuscar" placeholder="Buscar" onChange={e => this.setState({ nome: e.target.value })} />
                        </div>
                        <div className="bd-highlight">
                            <button type="submit" className="btn btn-coop reset">Buscar</button>
                        </div>
                    </form>
                </div>

            </div>

            <div className="row mt-3">
                <div className="col-12">
                    {this.state.isInload &&
                        <div id="#divAguarde">
                            <div className="d-flex justify-content-center">
                                <div className="spinner-border text-secondary" role="status">
                                    <span className="sr-only">Aguarde...</span>
                                </div>
                            </div>
                        </div>}

                    <Alert visible={this.state.showAlert} onSetMessager={this.showMessage} ref={this.alert} showCloseButton={false}></Alert>

                    <div id="#divSucesso">
                        <div className="table-responsive">
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th>Nome</th>
                                        <th>Usuario</th>
                                        <th>Função</th>
                                        <th>status</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.users.map((x: IUser, index: number) =>
                                            <tr key={index}>
                                                <td>{x.nome}</td>
                                                <td>{x.userName}</td>
                                                <td>{x.roles?.join(',')}</td>
                                                <td>{x.ativo ? 'ativo' : 'inativo'}</td>
                                                <td>
                                                    <button type="button" className="btn btn-sm btn-outline-secondary" data-toggle="modal" data-target="#ModalCadastro" onClick={() => this.setState({ editUser: x })}>Editar</button>
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>

                        <Pagination page={this.state.page} itemsPerPage={this.state.qtdeItems} tot={this.state.tot} onChangePage={e => this.handlerChangePage(e)}  ></Pagination>
                    </div>
                </div>
            </div>
            <ModalUser user={this.state.editUser} onSucess={() => { this.handlerOnSuccess() }} onClose={() => { this.handlerResetEditUser() }}></ModalUser>
        </div >
        );
    }
}


export default withRouter(Index);