import React, { Component } from 'react';
import Menu from '../../components/nav';
import Footer from '../../components/footer';
import './index.css';

class index extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="page-container">
                    <div className="content-wrap">
                        <Menu></Menu>
                        {this.props.children}
                        <Footer></Footer>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default index;