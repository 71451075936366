import React, { Component } from 'react';

class index extends Component {
    componentWillMount() {

    }

    componentDidMount() {

    }

    render() {
        return (
            <div className="box">
                Não autorizado
            </div>
        );
    }
}

export default index;