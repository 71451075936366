import React, { Component } from 'react';
import InputMask from 'react-input-mask';
import ClientList from '../../components/client-list';
import Alert, { MessagerType } from '../../components/Alert';
import api from '../../services/api';
import { AxiosResponse } from 'axios';
import { serializeModels, removeCharacteres } from '../../common/serialize';
import IClient from '../../Models/Client';
import Erros from '../../configs/erros';
enum CriterioBusca {
    CPF = 'cpf',
    Matricula = 'matricula'
}

class index extends Component {
    private alert: React.RefObject<Alert>;

    state: {
        value: string,
        criterio: CriterioBusca,
        isInload: false,
        formSubmited: false,
        inputMask: string | RegExp[],
        client: IClient,
        showAlert: boolean
    };
    /**
     *
     */
    constructor(props: any) {
        super(props);
        this.alert = React.createRef();
        this.state = {
            value: '',
            isInload: false,
            formSubmited: false,
            inputMask: "999.999.999-99",
            criterio: CriterioBusca.CPF,
            client: {} as IClient,
            showAlert: false
        }
    }
    closeAlert = () => {
        this.setState({ showAlert: false });
    }

    showMessage = () => {
        this.setState({ showAlert: true });
    }

    handlerSubmit = async (e: React.FormEvent<HTMLFormElement>) => {

        e.preventDefault();
        let { criterio, value } = this.state;

        let filter: { [key: string]: string } = {};
        if (criterio === CriterioBusca.CPF)
            value = removeCharacteres(value);
        filter[criterio] = value;
        this.handlerSearch(filter);
    }

    handlerSearch = async (filter: {}) => {
        this.closeAlert();
        this.setState({ isInload: true, client: {} as IClient, formSubmited: false });
        try {
            const response = await api.get("/api/Cliente?".concat(serializeModels(filter)));
            let data = response.data as IClient;
            this.setState({ client: data, formSubmited: true, isInload: false });
            if (data.idCliente === 0 || !data)
                this.alert.current?.showAlert(MessagerType.warning, "CPF não encontrado. Convide o cliente a se tornar cooperado, mostrando todas as vantagens e benefícios de apresentar o CPF no caixa.");
        } catch (err) {
            this.setState({ isInload: false });
            if (err.isAxiosError) {

                const { data } = err.response as AxiosResponse;
                if (data.errors) {
                    this.alert.current?.showAlert(MessagerType.error, Erros[Object.keys(data.errors)[0]]);
                } else
                    this.alert.current?.showAlert(MessagerType.error,
                        "Houve um problema interno, Tente novamente mais tarde.");
            }
            else {
                this.alert.current?.showAlert(MessagerType.error,
                    "Houve um problema interno, Tente novamente mais tarde.");
            };
        };
    }

    handlerChangeMask = (mask: string | RegExp[], criterio: CriterioBusca, checked: boolean): void => {
        if (checked) {
            this.setState({ inputMask: mask, criterio })
        }
    }

    render() {
        const { client, inputMask } = this.state;
        return (
            <div className="mt-3">
                <header className="container-lg my-4 my-sm-5">
                    <div className="card card-header sombra">
                        <div className="card-body p-2 p-sm-4">
                            {/* FORM BUSCA */}
                            <form onSubmit={this.handlerSubmit}>
                                <div className="row">
                                    <div className="col-12 col-lg-5 col-xl-4 pt-md-0 pt-lg-2 pb-3 pb-lg-0">
                                        <div className="busca-title pb-2 pb-lg-0 d-md-block d-lg-inline mr-0 mr-md-3">Buscar por:</div>

                                        <div className="custom-control custom-radio custom-control-inline mr-3">
                                            <input defaultChecked={true} type="radio" id="radioCPF" name="inputradio" className="custom-control-input" value="CPF" onChange={e => this.handlerChangeMask("999.999.999-99", CriterioBusca.CPF, e.target.checked)} />
                                            <label className="custom-control-label" htmlFor="radioCPF">CPF</label>
                                        </div>
                                        <div className="custom-control custom-radio custom-control-inline">
                                            <input type="radio" id="radioMatricula" name="inputradio" className="custom-control-input" value="Matricula" onChange={e => this.handlerChangeMask([/^\d+$/], CriterioBusca.Matricula, e.target.checked)} />
                                            <label className="custom-control-label" htmlFor="radioMatricula">Matrícula</label>
                                        </div>
                                    </div>

                                    <div className="col-12 col-lg-7 col-xl-8">
                                        <div className="d-flex bd-highlight">
                                            <div className="pr-2 flex-grow-1 bd-highlight">
                                                <InputMask id="txtBuscar" placeholder="Buscar" className="form-control form-control-lg" mask={inputMask} onChange={e => this.setState({ value: e.target.value })} />
                                            </div>
                                            <div className="bd-highlight">
                                                <button type="submit" className="btn btn-lg btn-coop">Buscar</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            {/* FORM BUSCA */}
                        </div>
                    </div>
                </header>

                <main className="container-lg mt-4">
                    {/* <!-- DIV AGUARDE --> */}
                    <div id="#divAguarde" className={this.state.isInload ? '' : 'd-none'}>
                        <div className="d-flex justify-content-center">
                            <div className="spinner-border text-secondary" role="status">
                                <span className="sr-only">Aguarde...</span>
                            </div>
                        </div>
                    </div>


                    <Alert visible={this.state.showAlert} onSetMessager={this.showMessage} ref={this.alert} showCloseButton={false}></Alert>
                    {
                        (client.idCliente > 0 && !this.state.isInload) &&
                        <ClientList data={client}></ClientList>
                    }

                </main>
            </div>
        );
    }
}
export default index;