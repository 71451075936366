import { setCookie, getCookie } from './../common/cookies';
const TOKEN_KEY: string = "coopToken";
const USER_KEY: string = "user";

export const isAuthenticated = () => !!getToken();

export function getToken(): string | null {
  
    return getCookie(TOKEN_KEY);
}
export function getCurrentUser(): User | null {
    const stringUser = localStorage.getItem(USER_KEY);
    if (stringUser) {
        const user: User = JSON.parse(stringUser as string);
        return user;
    }
    return null;
}

export function login(userResponse: LoginResponse): void {
    let path = process.env.PUBLIC_URL.includes('http') ? '/' : process.env.PUBLIC_URL
    setCookie(TOKEN_KEY, userResponse.token, userResponse.expiress, path);
    localStorage.setItem(USER_KEY, JSON.stringify(userResponse.user));
};

export function logout(): void {
    let path = process.env.PUBLIC_URL.includes('http') ? '/' : process.env.PUBLIC_URL
    setCookie(TOKEN_KEY, '', -1, path);
    localStorage.removeItem(USER_KEY);
};

export function isAdmin(): boolean {
    const user = getCurrentUser();
    if (user) {
        return !!user.roles.filter(x => x === "admin").length;
    }
    return false;
}

export function isInRole(roles?: Array<string>): boolean {
    const user = getCurrentUser();
    if (user) {
        //se for null quer dizer que é liberada para todos usuarios autenticados

        return !roles ? true : !!user.roles.filter(x => roles?.includes(x)).length;

    }
    else return false;
}

export interface User {
    id: number;
    email: string;
    nome: string;
    userName: string;
    senha: string;
    ativo: boolean;
    dataCriacao: Date;
    ultimaModificacao: Date;
    roles: string[];
}

export interface LoginResponse {
    user: User;
    token: string;
    expiress: number;
}
