export const setCookie = (cname: string, cvalue: any, exSecs: number, path?: string): void => {
    var d = new Date();
    d.setTime(d.getTime() + (exSecs * 1000));
    var expires = "expires=" + d.toUTCString();
   
    if (!path)
        path = '/';

    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=" + path;
}

export const getCookie = (cname: string): string => {
    const name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}