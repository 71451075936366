import React, { FormEvent, Component } from 'react';
import api from '../../services/api';
import { login } from '../../services/auth';
import { AxiosResponse } from 'axios';
import { withRouter } from 'react-router-dom';
import Alert, { MessagerType } from '../../components/Alert';
import { Helmet } from 'react-helmet';
import Logo from '../../assets/img/logo-escuro.png';
import './login.css';
class Index extends Component<any> {
    public alert: React.RefObject<Alert>;

    state = {
        userName: "",
        password: "",
        error: "",
        isInload: false,
        showAlert: false
    };

    constructor(props: any) {
        super(props);
        this.alert = React.createRef();
    }
    closeAlert = () => {
        this.setState({ showAlert: false });
    }

    showMessage = () => {
        this.setState({ showAlert: true });
    }

    handlerRedirect() {
        const { history, location } = this.props;
        const from = location.state?.from;
        if (from)
            history.push(from);
        else
            history.push("/");
    }

    handleSignIn = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
        e.preventDefault();
        this.closeAlert();
        const { userName, password } = this.state;
        if (!userName || !password) {
            this.alert.current?.showAlert(MessagerType.warning, "Preencha usuario e senha para continuar!");
        } else {
            try {
                this.closeAlert();
                this.setState({ isInload: true });
                const response = await api.post("/api/Auth/SignIn", { userName, password });
                login(response.data);
                this.handlerRedirect();
            } catch (err) {
                if (err.isAxiosError) {
                    const { status } = err.response as AxiosResponse;
                    if (status === 404) {
                        this.alert.current?.showAlert(MessagerType.error,
                            "Login/Senha inválidos.");
                    } else if (status === 422) {
                        this.alert.current?.showAlert(MessagerType.error,
                            "O usuario esta  desativado.");
                    }
                    else {
                        this.alert.current?.showAlert(MessagerType.error,
                            "Houve um problema interno, Tente novamente mais tarde.");
                    }
                }
                else {
                    this.alert.current?.showAlert(MessagerType.error,
                        "Houve um problema interno, Tente novamente mais tarde.");
                };
                this.setState({ isInload: false });
            };           
        }
    }

    render() {
        return (
            <React.Fragment>
                <Helmet title="Consulta de Perfil - Login" />
                <div className="container-signin">
                    <form onSubmit={this.handleSignIn} className="form-signin text-center" >
                        <img src={Logo} alt="COOP" width="200" />
                        <h1 className="h3 my-5 font-weight-normal">Consulta de Perfil</h1>

                        <label htmlFor="txtLogin" className="sr-only">E-mail ou Usuario</label>
                        <input
                            id="txtLogin"
                            className="form-control"
                            type="text"
                            placeholder="E-mail ou Usuario"
                            onChange={e => this.setState({ userName: e.target.value })}
                            required
                        />

                        <label htmlFor="txtPassword" className="sr-only" >Senha</label>
                        <input
                            id="txtPassword"
                            className="form-control"
                            type="password"
                            placeholder="Senha"
                            required
                            onChange={e => this.setState({ password: e.target.value })}
                        />

                        <div id="#divAguarde" className={this.state.isInload ? '' : 'd-none'}>
                            <div className="d-flex justify-content-center mb-2 mt-1">
                                <div className="spinner-border text-secondary" role="status">
                                    <span className="sr-only">Aguarde...</span>
                                </div>
                            </div>
                        </div>

                        <Alert visible={this.state.showAlert} onSetMessager={this.showMessage} ref={this.alert} showCloseButton={false}></Alert>
                        <button type="submit" className="btn btn-lg btn-coop btn-block">Entrar</button>
                        <p className="mt-5 mb-3 text-muted">&copy; IBOPEdtm</p>
                    </form>

                </div>
            </React.Fragment>
        );
    }
}
export default withRouter(Index);