import React, { Component } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom'
import { isInRole, logout, isAdmin, getCurrentUser } from '../../services/auth';
import { withRouter } from 'react-router-dom';
import Logo from '../../assets/img/logo.png';

export interface MenuProps extends RouteComponentProps {

}

class Menu extends Component<MenuProps> {

    public vt: number = 0;

    handlerLogout = () => {
        logout();
        const { history } = this.props;
        history.push('/')
    }

    isUserPage = () => {
        const { location } = this.props;
        return location.pathname.includes('user');
    }

    render() {
        const user = getCurrentUser();
        return (isInRole() &&
            <div>
                <React.Fragment>
                    <nav className="navbar navbar-dark px-0">
                        <div className="container-lg">
                            <div className="d-inline-flex d-flex align-items-center logo">
                                <img src={Logo} alt="COOP" loading="lazy" />
                                <span className="ml-2">Consulta <br className="brqubra" />de Perfil</span>
                            </div>

                            <div className="float-right text-right">
                                <small className="text-light nomelog text-capitalize">{user?.nome}</small><br className="brqubra" />
                                {(isAdmin() && !this.isUserPage()) &&
                                    <Link to="/admin/user" className="btn btn-sm btn-outline-light ml-2">
                                        <i className="fa fa-user" aria-hidden="true"></i> Usuarios
                                    </Link>
                                }
                                {(isAdmin() && this.isUserPage()) &&
                                    <Link to="/" className="btn btn-sm btn-outline-light ml-2">
                                        <i className="fa fa-home" aria-hidden="true"></i> home
                                    </Link>
                                }
                                <button type="button" onClick={this.handlerLogout} className="btn btn-sm btn-outline-light ml-2"><i className="fa fa-power-off" aria-hidden="true"></i>Sair</button>
                            </div>
                        </div>
                    </nav>

                </React.Fragment>

            </div >
        );
    }
}

export default withRouter(Menu);