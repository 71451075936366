import React, { Component } from 'react';
import api from '../../../services/api';
import Alert, { MessagerType } from '../../../components/Alert';
import { AxiosResponse } from 'axios';
import { IUser } from '../../../Models/User';
import { Validators } from '../../../common/validators';
import Erros from '../../../configs/erros';

export interface ModalUserProps {
    user: IUser,
    onSucess?: () => void,
    onClose?: () => void,
}

class index extends Component<ModalUserProps> {
    public alert: React.RefObject<Alert>;
    state: {
        id: number,
        email: string,
        nome: string,
        userName: string,
        senha: string,
        ativo: boolean,
        roles: string[],
        erros: string,
        showAlert: boolean,
        validEmailsDomain: string[]
    }
    constructor(props: ModalUserProps) {
        super(props);
        this.alert = React.createRef();
        this.state = { ...this.props.user, validEmailsDomain: [], erros: '', showAlert: false };
        this.handlerGetValidEmailDomains();
    }

    componentDidUpdate() {
        if (this.state.id !== this.props.user.id && this.props.user.id !== undefined)
            this.setState({ ...this.props.user });
    }

    closeAlert = () => {
        this.setState({ showAlert: false });
    }

    showMessage = () => {
        this.setState({ showAlert: true });
    }

    validateFileds = (): boolean => {
        let isValid = true;
        let erros = [];
        if (!Validators.required(this.state.nome)) {
            isValid = false;
            erros.push('O nome é requerido');
        }
        else if (!Validators.fullName(this.state.nome)) {
            isValid = false;
            erros.push('Digite o nome completo valido');
        }

        if (!Validators.isEmail(this.state.email)) {
            isValid = false;
            erros.push('Email esta invalido');
        }
        if (!Validators.required(this.state.userName)) {
            isValid = false;
            erros.push('Digite o nome de usuario');
        } else if (!Validators.maxLength(this.state.userName, 20)) {
            isValid = false;
            erros.push('O nome de usuario são ate 20 caracteres');
        }

        if (!Validators.required(this.state.senha)) {
            isValid = false;
            erros.push('Digite sua senha');
        } else if (!Validators.maxLength(this.state.senha, 8)) {
            isValid = false;
            erros.push('A senha são ate 8 caracteres');
        }

        if (!Validators.required(this.state.roles)) {
            isValid = false;
            erros.push('Informe a função');
        }
        if (!isValid)
            this.alert.current?.showAlert(MessagerType.error, erros.join('<br />'));


        return isValid;

    }

    handlerCheckedRoles = (role: string, checked: boolean) => {
        if (checked) {
            this.setState({ roles: [role] });
        }
    }

    handlerDelete = async (id: number, e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        this.closeAlert();
        if (window.confirm('deseja realmente deletar o registro')) {
            try {
                await api.delete("/api/AdminUser/" + id);
                this.alert.current?.showAlert(MessagerType.success,
                    "O Registro foi deletado com sucesso.");
                this.props.onSucess?.call(this);
            } catch (err) {
                if (err.isAxiosError) {
                    const { statusText } = err.response as AxiosResponse;
                    this.alert.current?.showAlert(MessagerType.error,
                        "Houve um problema interno, Tente novamente mais tarde.");
                    console.log(statusText);
                }
                else {
                    this.alert.current?.showAlert(MessagerType.error,
                        "Houve um problema interno, Tente novamente mais tarde.");
                };
            };
        }
    }

    hanlderSaveUser = async () => {
        this.closeAlert();
        const { email, nome, userName, senha, ativo, roles } = this.state;
        try {
            await api.post("/api/AdminUser/", {
                email,
                nome,
                userName,
                senha,
                ativo,
                roles,
            });
            this.alert.current?.showAlert(MessagerType.success, 'O usuario foi salvo com sucesso');
            this.props.onSucess?.call(this);
        } catch (err) {
            if (err.isAxiosError) {
                const { data } = err.response as AxiosResponse;
                if (data.errors) {
                    this.alert.current?.showAlert(MessagerType.error,
                        Erros[Object.keys(data.errors)[0]]);
                }
                else {
                    this.alert.current?.showAlert(MessagerType.error,
                        "Houve um problema interno, Tente novamente mais tarde.");
                }
            }
            else {
                this.alert.current?.showAlert(MessagerType.error,
                    "Houve um problema interno, Tente novamente mais tarde.");
            };
        };
    }
    hanlderUpdateUser = async () => {
        this.closeAlert();
        const { id, email, nome, userName, senha, ativo, roles } = this.state;
        try {
            await api.put("/api/AdminUser/", {
                id, email, nome, userName, senha, ativo, roles
            });
            this.alert.current?.showAlert(MessagerType.success, 'O usuario foi salvo com sucesso');
            this.props.onSucess?.call(this);
        } catch (err) {
            if (err.isAxiosError) {
                const { data } = err.response as AxiosResponse;
                if (data.errors) {
                    this.alert.current?.showAlert(MessagerType.error,
                        Erros[Object.keys(data.errors)[0]]);
                }
                else {
                    this.alert.current?.showAlert(MessagerType.error,
                        "Houve um problema interno, Tente novamente mais tarde.");
                }
            }
            else {
                this.alert.current?.showAlert(MessagerType.error,
                    "Houve um problema interno, Tente novamente mais tarde.");
            };
        };
    }

    onClose = () => {
        this.props.onClose?.call(this);
        this.closeAlert();
    }

    handlerOnsubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (this.validateFileds()) {
            if (this.state.id > 0)
                this.hanlderUpdateUser();
            else
                this.hanlderSaveUser();
        }
    }

    handlerGetValidEmailDomains = async () => {
        try {
            const response = await api.get("/api/AdminUser/GetValidEmailDomain");
            this.setState({ validEmailsDomain: response.data });
        } catch (err) {
            console.log(err);
        };
    }

    render() {
        return (
            <React.Fragment>
                {/* <!-- Modal --> */}

                <div className="modal fade" id="ModalCadastro" data-backdrop="static" data-keyboard="false" aria-hidden="true">
                    <div className="modal-dialog">
                        <form onSubmit={e => this.handlerOnsubmit(e)} className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Cadastro</h5>

                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.onClose}>
                                    Fechar <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="form-group row">
                                    <label htmlFor="nome" className="col-sm-4 col-form-label"  >Nome completo</label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control" id="nome" value={this.state.nome}
                                            onChange={e => this.setState({ nome: e.target.value })} />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="email" className="col-sm-4 col-form-label">E-mail</label>
                                    <div className="col-sm-8">
                                        <input type="mail" className="form-control" id="email" value={this.state.email}
                                            onChange={e => this.setState({ email: e.target.value })} />
                                        <small id="emailHelp" className="form-text text-muted">{this.state.validEmailsDomain.length === 0 ? '' : <React.Fragment>Dominios validos:<strong> ({this.state.validEmailsDomain.join(', ')} )</strong></React.Fragment>}</small>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label htmlFor="usuario" className="col-sm-4 col-form-label">Usuário</label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control" maxLength={20} id="usuario" value={this.state.userName} onChange={e => this.setState({ userName: e.target.value })} />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="senha" className="col-sm-4 col-form-label">Senha</label>
                                    <div className="col-sm-8">
                                        <input type="password" className="form-control" maxLength={8} id="senha" value={this.state.senha} onChange={e => this.setState({ senha: e.target.value })} />
                                    </div>
                                </div>

                                <div className="row">
                                    <label className="col-sm-4">Função</label>
                                    <div className="col-8">
                                        <div className="custom-control custom-checkbox custom-control-inline mr-2">
                                            <input className="custom-control-input" checked={this.state.roles[0] === 'user'} type="radio" name="rolesRadion" id="userRadion" onChange={(value: React.ChangeEvent<HTMLInputElement>) => this.handlerCheckedRoles('user', value.target.checked)} />

                                            <label className="custom-control-label" htmlFor="userRadion">User</label>
                                        </div>

                                        <div className="custom-control custom-checkbox custom-control-inline mr-2">
                                            <input className="custom-control-input" checked={this.state.roles[0] === 'admin'} type="radio" name="rolesRadion" id="adminRadion" onChange={(value: React.ChangeEvent<HTMLInputElement>) => this.handlerCheckedRoles('admin', value.target.checked)} />
                                            <label className="custom-control-label" htmlFor="adminRadion">Admin</label>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- ESSA DIV Só É ATIVADA QUANDO CLICAR EM EDITAR CADASTRO --> */}
                                <div className="mt-5" id="visible">
                                    <div className="form-group row">
                                        <label htmlFor="status" className="col-sm-4 col-form-label">Status</label>
                                        <div className="col-sm-8">
                                            <select className="custom-select" id="status" value={this.state.ativo.toString()} onChange={e => this.setState({ ativo: e.target.value === 'true' ? true : false })} >
                                                <option value="true" >Ativo</option>
                                                <option value="false">Inativo</option>
                                            </select>
                                        </div>
                                    </div>
                                    {this.state.id > 0 &&
                                        <div className="row">
                                            <label htmlFor="status" className="col-sm-4 col-form-label">Remover</label>
                                            <div className="col-8">
                                                <button type="button" className="btn btn-sm btn-link" onClick={e => this.handlerDelete(this.state.id, e)} > Excluir</button>
                                            </div>
                                        </div>}
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <Alert visible={this.state.showAlert} onSetMessager={this.showMessage} ref={this.alert} showCloseButton={false}></Alert>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer ">
                                <button type="submit" className="btn btn-lg btn-block btn-outline-success">Salvar</button>
                            </div>
                        </form>

                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default index;